'use client';

import React from 'react';
import { useRouter } from 'next/navigation';
import Inbox from '~/app/_components/Inbox/Inbox';
import Meetings from '~/app/_components/Meetings/Meetings';
const Page: React.FC = () => {
  const router = useRouter();
  React.useEffect(() => {
    router.push('/report');
  }, [router]);
  return null; // Return null as we are redirecting
};
export default Page;